.generalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}
.boxText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.linha{
  display:flex;
  justify-content:center;
  border-top:1px solid #000
}

.botaoPoemas {
  display: flex;
  justify-content: center;
}

.botaoTextPoemas {
  padding: 0.5em 1.5em;
  margin: 2em 1em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
}