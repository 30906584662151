.generalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}
.boxText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.tituloDoPoema{
  font-size:14px;
  border-bottom: 4px solid #000;
  padding-bottom: 1em;
}
.textoPoema{
  margin-top:1em;
}
.poemasCentro{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000;
}

.poemasEsquerda{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #000;
}

