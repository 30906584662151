.generalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}

.contentArticle{
  width: 30em;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1em 0;
  text-align: left;
}

.titleArticle{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.botaoArtigo{
  padding: 0.5em 1.5em;
  margin: 1em 0em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
  max-width: fit-content;
}

.botoesRodapeArtigos{
  display: flex;
}

.botaoArtigoEBlog{
  padding: 0.5em 1.5em;
  margin: 2em 1em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
}

@media(max-width:768px){
  .contentArticle{
    width: fit-content;
    justify-content: center;
    align-items: center;
  }
 
}

