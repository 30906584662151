.generalContent{
  margin-top:2em;
}
.banner{
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.leonardoAraujo{
  margin: 0 auto;
}
.boxText{
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxText p{
  color: #000;
  text-decoration: none;
  font-family: "LatoLight", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: justify;
  display: flex;
  align-items: center;
  margin-top:2em;
}

