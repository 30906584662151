.generalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}
.boxText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}
.fotoLeoEsquerda img {
  margin: 0 auto;
}

.citacao {
  display: flex;
  align-items: center;
  align-self: center;
  border-left: 4px solid #000000;
  padding-left: 1em;
  height: 5em;
}

.divisaoHistoria {
  margin-top: 2em;
}

.botaoPoesia {
  display: flex;
  justify-content: center;
  margin-top:-1.5em;
}

.botaoTextPoesia {
  padding: 0.5em 1.5em;
  margin: 2em 1em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
}

.textoInteiro{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  font-size: 16px;
  margin: 2em 0;
}

.textoLateral{
  height: fit-content;
  border-left: 4px solid #000;
  padding-left: 1em;
}