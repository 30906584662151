.generalContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
}
.contentAtividades{
  border-bottom: 1px solid #000;
  display: flex;
}
.boxText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.terceiroTitulo{
  color: #000000;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.7px;
  text-align: center;
}

.headerTable{
  border-top: 4px solid #000;
  border-bottom: 1px solid #000;
  padding: 0.5em;
  text-align: center;
  font-weight: bold;
}

.saibaMais{
  text-align: center;
  margin-top:2em;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.saibaMais:hover{
  color:#000;
}

@media(max-width:768px){
  .contentAtividades{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
}