.generalContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
}

.boxText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.email{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #000;
  margin-bottom: 2em;
}

.email a{
  color: #000;
  margin-bottom: 1em;
  letter-spacing: 1.4px;
  font-weight: 400;
}

.email a:hover{
  color: #000;
  text-decoration: none;
}

.botaoContato{
  display: flex;
}
.botaoFaleComigo{
  padding: 0.5em 1.5em;
  margin: 2em 1em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
}

.modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width:40%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px solid #000;
    border-radius: 9px;
    outline: none;
    padding: 2em 0;
}

.modal h2{
  text-align: center;
}

@media(max-width:768px){
  .modal{
    width: 90%;
  }
}