.generalContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
}

.secondContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
  border-bottom: 1px solid #000;
}
.boxText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.botoesConteudo{
  display: flex;
}

.botaoArtigoELivros{
  padding: 0.5em 1.5em;
  margin: 2em 1em;
  background-color: transparent;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: bold;
}
