.twoLines{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.select{
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
  height: 3em;
  margin-top: 1.5em;
}

.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 80%;
  background-color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: #ccc;
  border-radius: 9px;
  outline: none;
  padding-bottom: 10px;
  padding-top: 11px;
}

.headerLogo{
  display: flex;
  justify-content: space-between;
}