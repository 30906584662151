.generalContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 2em;
  }
  .contentAtividades{
    border-bottom: 1px solid #000;
    display: flex;
  }
  .boxText{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
  .fotoLeoEsquerda img{
    margin: 0 auto;
  }
  
  .citacao{
    display: flex;
    align-items: center;
    align-self: center;
    border-left:4px solid #000000;
    padding-left: 1em;
    height: 5em;
  }
  
  .classe.carousel-indicators{
    background-color: #000 !important;
  }
  .classe.carousel-indicators li{
    background-color: red;
    color: #fff;
  }
  
  
  .sliderPalestras{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
  }
  
  .sliderPalestras img{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sliderPalestras h3{
    font-size: 16px;
    text-transform: uppercase;
    margin: 0.5em 0 0.1em 0;
  }
  
  .sliderPalestras h4{
    font-size: 15px;
  }

  .infos{
    display: flex;
    margin-top:1em;
  }

  .date{
    padding: 0 1em 0 0;
    border-right: 1px solid #000;
  }

  .saibaMais{
    color:#000;
    margin-left:1em;
  }

  .saibaMais:hover{
    text-decoration: none;
    color:#000;
  }