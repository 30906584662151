.login {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.login::before {
  display: block;
  content: '';
  background-size: cover;
}

.forms {
  max-width: 30rem;
  padding: 1rem;
}

@media (max-width: 40rem) {
  .login {
    grid-template-columns: 1fr;
  }
  .login::before {
    display: none;
  }
  .forms {
    max-width: 100%;
  }
}
