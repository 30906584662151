
.firstHeader{
  margin-top: 1em;
}
.firstHeader ul{
  padding:0px;
  margin:0px;
}
.firstHeader ul li{
  display: inline;
}
.firstHeader ul li a{
  display: inline-block;
}

.secondHeader {
  width: 100%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-top:4px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thirdHeader{
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
}

.thirdHeader ul{
  padding:0px;
  margin:0px;
  text-align: center;
}
.thirdHeader ul li{
  display: inline;
}
.thirdHeader ul li a{
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  padding: 0.5em;
  display: inline-block;
}

.nav {
  padding: 2.5em;
}

.logo {
  padding: 0.5rem 0;
}

