.generalContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
  border-top:4px solid #000;
  padding: 2em 0;
} 

.boxText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.terceiroTitulo{
  color: #000000;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.7px;
  text-align: center;
}

.email a{
  color: #000;
  margin-bottom: 1em;
  letter-spacing: 1.4px;
  font-weight: 400;
}

.email a:hover{
  color: #000;
  text-decoration: none;
}

